import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Gps from "/src/components/img/gps"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Studijní materiály Základy geodézie a GPS" />
    <h1>Studijní materiály Základy geodézie a GPS</h1>
    <p>Níže jsou sepsány&nbsp;on-line učební materiály <strong>z předmětu <strong><strong>Z8109&nbsp;Základy geodézie a GPS</strong></strong></strong> na brněnské geografii&nbsp;na MUNI z jarního semestru 2019. Jde o zpracované otázky ke zkoušce na základě přednášek, mnoho jich však v níže uvedeném seznamu chybí a studijní materiály tak nejsou kompletní.</p>
    <p>K úspěšnému absolvování předmětu bylo kromě složení ústní zkoušky z předem zadaných otázek (část z nich je vypracována výše) třeba složit i praktickou zkoušku. Šlo o postavení teodolitu (do 10 minut vč.&nbsp;horizontace a centrace) a provedení jednoduchého měření. Dále bylo třeba zjistit převýšení mezi 2 body pomocí nivelační latě a nivelačního přístroje.</p>
    <p><em>(Kdyby se Vám náhodou chtělo, můžete mě podpořit lajkem na <a href="https://www.facebook.com/zemepisec">Fejsbůku</a>)</em></p>
    <hr />
    <ImgCon><Gps /><div><h2>Co je geodézie? Co znamená zkratka GPS?</h2>
<p>Podle definice je <strong>geod&eacute;zie </strong>jednak př&iacute;rodn&iacute; věda, kter&aacute; zpomoc&iacute; geometrick&yacute;ch a fyzik&aacute;ln&iacute;ch metod <strong>z&iacute;sk&aacute;v&aacute; o Zemi &uacute;daje metrick&eacute;ho a fyzik&aacute;ln&iacute;ho charakteru</strong>. Geod&eacute;zie je v&scaron;ak i technick&yacute;m oborem <strong>zji&scaron;ťuj&iacute;c&iacute; geometrick&eacute; &uacute;daje</strong> pro tvorbu map a pro potřeby jin&yacute;ch oborů. Zkratka GPS vych&aacute;z&iacute; z anglick&eacute;ho <strong>Global Positioning System</strong>, tedy glob&aacute;ln&iacute; pozičn&iacute; syst&eacute;m. Jde o nejzn&aacute;měj&scaron;&iacute; <strong>syst&eacute;m glob&aacute;ln&iacute;ch navigačn&iacute;ch satelitn&iacute;ch syst&eacute;mu</strong> (zkratka <strong>GNSS</strong>).</p>
    <p><em>(podrobněji v kapitolách <Link to="/zaklady-geodezie-gps/dejiny-geodezie/">Dějiny geodézie</Link> a <Link to="/zaklady-geodezie-gps/gnss-systemy/">GNSS systémy a&nbsp;jejich složení</Link>)</em></p></div></ImgCon>
    <hr />
    <h2>Témata studijních materiálů geodézie, GPS a GNSS</h2>
    <ul>
    <li><Link to="/zaklady-geodezie-gps/dejiny-geodezie/">Dějiny geod&eacute;zie</Link></li>
    <li><Link to="/zaklady-geodezie-gps/rozdeleni-geodezie/">Rozdělen&iacute; geod&eacute;zie</Link></li>
    <li><Link to="/zaklady-geodezie-gps/mereni-uhlu-smeru/">Měřen&iacute; &uacute;hlů (směrů)</Link></li>
    <li><Link to="/zaklady-geodezie-gps/pristroje-a-pomucky-pro-mereni-uhlu-smeru/">Př&iacute;stroje a pomůcky pro měřen&iacute; &uacute;hlů a směrů</Link></li>
    <li><Link to="/zaklady-geodezie-gps/priprava-pristroje-chyby-mereni-uhlu/">Př&iacute;prava př&iacute;stroje na měřen&iacute;, chyby při měřen&iacute; &uacute;hlů</Link></li>
    <li><Link to="/zaklady-geodezie-gps/vypocet-smerniku-rajonu/">V&yacute;počet směrn&iacute;ku a raj&oacute;nu</Link></li>
    <li><Link to="/zaklady-geodezie-gps/gnss-systemy/">GNSS syst&eacute;my a jejich složen&iacute;</Link></li>
    <li><Link to="/zaklady-geodezie-gps/vysilane-signaly-gnss/">Vys&iacute;lan&eacute; sign&aacute;ly GNSS</Link></li>
    <li><Link to="/zaklady-geodezie-gps/katastr-nemovitosti/">Katastr nemovitost&iacute;</Link></li>
    </ul>
  </Layout>
)
